import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { path: '', pathMatch: 'full', loadChildren: () => import('./views/home/home.module').then(m => m.HomeModule) },
  { path: 'telemedicine', loadChildren: () => import('./views/telemedicine/telemedicine.module').then(m => m.TelemedicineModule) },
  { path: 'about', loadChildren: () => import('./views/about/about.module').then(m => m.AboutModule) },
  { path: 'contact', loadChildren: () => import('./views/contact/contact.module').then(m => m.ContactModule) },
  { path: 'covid-care', loadChildren: () => import('./views/covid/covid.module').then(m => m.CovidModule) },
  { path: 'research-experience', loadChildren: () => import('./views/research/research.module').then(m => m.ResearchModule) },
  { path: 'services', loadChildren: () => import('./views/services/services.module').then(m => m.ServicesModule) },
  { path: 'patient-information', loadChildren: () => import('./views/patient-info/patient-info.module').then(m => m.PatientInfoModule) },
  { path: 'special-offer', loadChildren: () => import('./views/special-offer/special-offer.module').then(m => m.SpecialOfferModule) },
  { path: 'privacy-policy', loadChildren: () => import('./views/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule) },
]
@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled',
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
