import { Component, OnInit } from '@angular/core';
import { InteractionService } from '../../../services/interaction.service';

@Component({
  selector: 'swapp-chat',
  templateUrl: './chat.component.html',
  styleUrls: [
    '../help-widget.component.scss',
    './chat.component.scss'
  ]
})
export class ChatComponent implements OnInit {

  constructor(
    private interact: InteractionService
  ) { }

  ngOnInit(): void {
  }

  closeUI(): void {
    this.interact.showHelpUI = '';
  }

}
