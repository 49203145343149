import { Component, OnInit, HostListener, Inject, PLATFORM_ID } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, Event } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { environment } from '../../../environments/environment';
import { APP_CONFIG } from '../../app.global';
import { InteractionService } from '../../services/interaction.service';

@Component({
  selector: 'swapp-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  menuShown = false;
  lastYPos: number;
  currentYPos = 0;
  upAnchorShown: boolean;
  isHomePage: boolean;
  infoPage: boolean;

  officeAddress: string;
  officeAddress2: string;
  telNumber: string;
  telNumber2: string;
  telNumberFormatted: string;
  telNumber2Formatted: string;
  gmapLink: string;
  gmapLink2: string;
  goingUp: boolean;

  @HostListener('window:scroll', ['$event'])
  doSomething(event) {
    this.lastYPos = this.currentYPos;
    this.currentYPos = window.pageYOffset;
    // this.getScrollDirection(this.currentYPos, this.lastYPos);
    this.upAnchorShown = window.pageYOffset > 500 ? true : false;
  }

  constructor(
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object,
    public interact: InteractionService
  ) {

    this.officeAddress = APP_CONFIG.address;
    this.officeAddress2 = APP_CONFIG.address2;
    this.telNumber = APP_CONFIG.telNo;
    this.telNumber2 = APP_CONFIG.telNo2;
    this.telNumberFormatted = APP_CONFIG.telNoFormatted;
    this.telNumber2Formatted = APP_CONFIG.telNo2Formatted;
    this.gmapLink = APP_CONFIG.mapLink;
    this.gmapLink2 = APP_CONFIG.mapLink2;

    this.router.events.subscribe((event: Event) => {
      this.menuShown = false;
      if (event instanceof NavigationEnd) {
        // console.log(event.url);
        this.isHomePage = event.url === '/' ? true : false;
        this.infoPage = event.url === '/covid-care' ? true : false;
      }
      // if (event instanceof NavigationEnd) {}
    });
  }

  ngOnInit() {
  }

  // getScrollDirection(current: number, prev: number) {
  //   if (current > prev) {
  //     this.goingUp = true;
  //   }
  //   if (prev > current) {
  //     this.goingUp = false;
  //   }
  // }

  scrollToElement(id: string) {
    // console.log(id);
    // console.log(this.isHomePage);
    if (!this.isHomePage && id !== 'header' && id !== 'hero') {
      this.router.navigate(['/'], { fragment: id })
        .then(() => {
          // console.log(el);
          setTimeout(() => {
            const el: HTMLElement = document.querySelector('#' + id);
            el.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }, 200);
          this.menuShown = false;
        });
    } else {
      setTimeout(() => {
        const el: HTMLElement = document.querySelector('#' + id);
        el.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 200);
      this.menuShown = false;
    }
  }

  login() {
    if (isPlatformBrowser(this.platformId)) {
      window.open(environment.GO_URL + 'n/login?cid=' + window.btoa(environment.SUBURBANCLINIC.id))
    }
  }

  showHelpWidget(mode: string) {
    this.interact.showHelpUI = mode;
  }
}
