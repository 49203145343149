<div class="help-widget aligned__right feedback">
  <div class="widget__header">
    <div class="title">
    </div>
    <div class="controls">
      <button class="close" (click)="closeUI()">&times;</button>
    </div>
  </div>
  <div class="widget__content">
    <div class="welcome">
      <div class="title">
        <span>We would be happy to hear</span>
        <h5>
          Your Feedback
        </h5>
      </div>
      <div class="icon">
        <img src="assets/images/widget-icon-feedback.png" alt="feedback icon">
      </div>
      <div class="form">
        <!-- <form action=""></form> -->
        <textarea placeholder="Write your feedback"></textarea>
        <button type="submit">Submit</button>
      </div>
    </div>
  </div>
</div>