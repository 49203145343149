<div id="top" *ngIf="infoPage; else covidTop"></div>
<ng-template #covidTop>
  <div class="covid__header" id="top">
    <div class="container">
      <p>
        <strong>
          Coronavirus (COVID-19)
        </strong>
        <a routerLink="/covid-care">What you should know and how to continue your care with us </a>
      </p>
      <p><strong>Disclaimer:</strong>For Emergency, call 911</p>
    </div>
  </div>
</ng-template>
<header id="header" class="site-header">
  <div class="top-header">
    <div class="top-header__container">
      <div class="logo">
        <a routerLink="/" class="logo-link" [class.nolink]="isHomePage">
          <img src="assets/images/logo.png" alt="Suburban Wellness SC Logo">
        </a>
        <div class="logo-text" [class.clickable]="!isHomePage" routerLink="/">
          <div class="up">Suburban Wellness SC</div>
          <div class="meta">Primary care with a difference</div>
        </div>
      </div>
      <div class="top-header__actions">
        <div class="action big">
          <div class="action-icon locate-btn"></div>
          <div class="action-text">
            <span>Address</span>
            <p>
              <a href="{{gmapLink}}" target="_blank">
                {{officeAddress}}
              </a>
              <a href="{{gmapLink2}}" target="_blank">
                {{officeAddress2}}
              </a>
            </p>
          </div>

        </div>
        <div class="action multi">
          <div class="action-btn call">
            <a href="tel:+17087377136" class="action-icon clickable"></a>
            <a href="tel:+17087377136" class="action-text">
              <span>Call Us</span>
              <small>Existing/New Patients</small>
              <em>+1 708-737-7136</em>
            </a>
          </div>
          <div class="action-btn text">
            <a class="action-icon" href="sms://+17734928354"></a>
            <a class="action-text" href="sms://+17734928354">
              <span>Text Us</span>
              <small>New Patients</small>
              <em>+1 773-492-8354</em>
            </a>
          </div>
        </div>
        <div class="action-cta">
          <button class="btn btn-primary" (click)="scrollToElement('appointments')">Book Appointment</button>
        </div>
      </div>
      <button class="hamburger" (click)="menuShown = !menuShown" title="Menu Button"
        aria-label="Toggle Navigation"></button>
    </div>
  </div>
  <nav class="menu-header" [class.shown]="menuShown">
    <div class="overlay" (click)="menuShown = false" *ngIf="menuShown"></div>
    <div class="inner-menu-wrapper">
      <ul>
        <li [class.active]="isHomePage" (click)="scrollToElement('appointments')"><a>Book Appointment</a></li>
        <li [routerLink]="['/special-offer']" routerLinkActive="active"><a>Special Offer</a></li>
        <li [routerLink]="['/about']" routerLinkActive="active"><a>About Practice</a></li>
        <li [routerLink]="['/services']" [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="active">
          <a>Services</a>
        </li>
        <li [routerLink]="['/patient-information']" routerLinkActive="active"><a>Patient Info</a></li>
        <li (click)="scrollToElement('testimonials')"><a>Testimonials</a></li>
        <li [routerLink]="['/contact']" routerLinkActive="active"><a>Contact</a></li>
        <li><a href="javascript:void(0)" (click)="login()">Log In</a></li>
        <!-- <li><a href="https://medium.com/@suburbanwellnessbiz" target="_blank" rel="noreferrer">Blog</a></li> -->
      </ul>
      <!-- <div class="mob-help-link">
        <details>
          <summary> <span class="icon">?</span> Help</summary>
          <div class="help-menus">
            <div class="feedback" (click)="menuShown = false; showHelpWidget('feedback')"> Feedback </div>
            <div class="live" (click)="menuShown = false; showHelpWidget('support')"> Live Customer Support</div>
          </div>
        </details>
      </div> -->
      <!-- <div class="social-links" [class.no-help]="interact.showHelpUI !== ''"> -->
      <div class="social-links no-help">
        <ul>
          <li><a href="https://www.facebook.com/suburbanwellnessSC/" target="_blank" rel="noreferrer"><img
                src="assets/images/facebook-logo.png" alt="facebook-logo"></a></li>
          <li><a href="https://twitter.com/suburban_sc" target="_blank" rel="noreferrer"><img
                src="assets/images/twitter-logo.png" alt="twitter-logo"></a></li>
          <li><a href="https://www.instagram.com/suburbanwellness/" target="_blank" rel="noreferrer"><img
                src="assets/images/instagram-logo.png" alt="instagram-logo"></a>
          </li>
          <li><a href="https://www.linkedin.com/company/suburban-wellness-healthcare/" target="_blank"
              rel="noreferrer"><img src="assets/images/linkedin-logo.png" alt="linkedin-logo"></a></li>
        </ul>
        <!-- <div class="dropdown" *ngIf="interact.showHelpUI === ''">
          <span class="help">?</span>
          <div class="dropdown-content help-dropdown">
            <div class="help-menus">
              <div class="feedback" (click)="showHelpWidget('feedback')"> Feedback </div>
              <div class="live" (click)="showHelpWidget('support')"> Live Customer Support</div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </nav>
</header>
<button class="go-up" *ngIf="upAnchorShown" (click)="scrollToElement('top')">Back to Top</button>
<button class="go-up-mobile" *ngIf="upAnchorShown" (click)="scrollToElement('top')">Back to Top</button>