import { Component, OnInit } from '@angular/core';
import { InteractionService } from '../../../services/interaction.service';

@Component({
  selector: 'swapp-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: [
    '../help-widget.component.scss',
    './feedback.component.scss'
  ]
})
export class FeedbackComponent implements OnInit {

  constructor(
    private interact: InteractionService
  ) { }

  ngOnInit(): void {
  }

  closeUI(): void {
    this.interact.showHelpUI = '';
  }

}
