export const environment = {
  production: true,
  ENV_NAME: 'prod',
  API_URL: 'https://patientmdtalk.com:443/',
  GO_URL: 'https://app.patientmd.com/#/',
  SUBURBANCLINIC: {
    id: 'a.majumdar_patientmd.com_biz',
    branchId: '1553063517944',
    docId: 'a.majumdar@patientmd.com',
    docName: 'Dr. Sudarsana Chakrabarti, MD'
  },
  GMAP_Key: 'AIzaSyCjbongHh5OwOmgILPb0UP4KWOO_DcDGpg'
};
