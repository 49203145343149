import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HelpWidgetComponent } from './help-widget.component';
import { PipesModule } from '../../pipes/pipes.module';
import { FeedbackComponent } from './feedback/feedback.component';
import { ChatComponent } from './chat/chat.component';

@NgModule({
  declarations: [
    HelpWidgetComponent,
    FeedbackComponent,
    ChatComponent,
  ],
  imports: [
    CommonModule,
    PipesModule
  ],
  exports: [
    HelpWidgetComponent,
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class HelpWidgetModule { }
