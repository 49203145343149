<section class="sub-footer" *ngIf="!excludedPage">
  <div class="container">
    <div class="sub-footer__wrapper">
      <div class="has-icon other-locations">
        <div class="icon locations"></div>
        <div class="info">
          <h5>Areas We Serve</h5>
          <p>
            Orland Park, Lockport, New Lenox, Mokena, Frankfort, Bolingbrook, Lisle, Lemont, Fairmont, Westmont
          </p>
        </div>
      </div>
      <div class="has-icon timings">
        <div class="icon time"></div>
        <div class="info">
          <h5>Office Hours</h5>
          <div class="timetable">
            <div>
              <h6>Homer Glen</h6>
              <ul>
                <li *ngFor="let item of timings1">
                  <strong>{{item.day}}</strong>
                  <span>{{item.timing}}</span>
                </li>
              </ul>
            </div>
            <div>
              <h6>New Lenox</h6>
              <ul>
                <li *ngFor="let item of timings2">
                  <strong>{{item.day}}</strong>
                  <span>{{item.timing}}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="book-cta">
        <button type="button" class="btn btn-primary" (click)="bookingViaFooterBtn()">Book Appointment</button>
      </div>
    </div>
  </div>
</section>
<footer>
  <div class="container">
    <div class="footer-menu">
      <ul>
        <li (click)="scrollToElement('appointments')"><a>Book Appointment</a></li>
        <li [routerLink]="['/special-offer']" routerLinkActive="active"><a>Special Offer</a></li>
        <li [routerLink]="['/about']" routerLinkActive="active"><a>About Practice</a></li>
        <li [routerLink]="['/services']" routerLinkActive="active"><a>Services</a></li>
        <li [routerLink]="['/privacy-policy']" routerLinkActive="active"><a>Privacy Policy</a></li>
        <!-- <li (click)="scrollToElement('testimonials')"><a>Testimonials</a></li> -->
      </ul>
    </div>
    <div class="address-ph">
      <div class="phone">
        <span class="title">Phone: </span>
        <span class="content">
          <em>+1 708-737-7136</em> (Existing/New Patients)
        </span>
      </div>
      <div class="phone">
        <span class="title">Text: </span>
        <span class="content">
          <em>+1 773-492-8354</em> (New Patients)
        </span>
      </div>
      <div class="phone">
        <span class="title">Fax: </span>
        <span class="content">
          <em>+1 708-887-5886</em>
        </span>
      </div>
      <div class="address">
        <span class="title">Address: </span>
        <address>15750 S Bell Road Suite 2D, Homer Glen, Illinois 60491</address>
        <address>1890 Silver Cross Blvd, Suite 215, New Lenox, IL 60451</address>
      </div>
    </div>
    <div class="power">
      <span>
        Powered by <a href="https://patientmd.com"> <span class="blu">Patient</span>MD</a>
      </span>
    </div>
  </div>
  <div class="copyright">
    <span>
      Copyright &copy; 2021 | all rights reserved
    </span>
  </div>
</footer>