export const APP_CONFIG = {
  address: '15750 S Bell Road Suite 2D, Homer Glen, Illinois 60491',
  address2: '1890 Silver Cross Blvd, Suite 215, New Lenox, IL 60451',
  telNo: '+16306697521',
  telNoFormatted: '630-669-7521',
  telNo2: '+17086454193',
  telNo2Formatted: '708-645-4193',
  mapLink: 'https://goo.gl/maps/AuehZUkA7UsBthqb8',
  mapLink2: 'https://goo.gl/maps/LkoG7cdYu8DkNAxC9',
  branches: [
    {
      id: '1553063517944',
      name: 'Homer Glen',
    },
    {
      id: '1636565066696',
      name: 'New Lenox',
    }
  ]
}
