import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimeFormatPipe } from './time-format.pipe';
import { TimeCheckerPipe } from './time-checker.pipe';
import { NiceDatePipe } from './nice-date.pipe';
import { TimesPipe } from './times.pipe';
import { HandleNamePipe } from './handle-name.pipe';
import { TimeZonePipe } from './time-zone.pipe';
import { UrlifyPipe } from './urlify.pipe';
import { TruncatePipe } from './truncate.pipe';

@NgModule({
  declarations: [
    TimeFormatPipe,
    TimeCheckerPipe,
    NiceDatePipe,
    TimesPipe,
    HandleNamePipe,
    TimeZonePipe,
    UrlifyPipe,
    TruncatePipe
  ],
  imports: [CommonModule],
  exports: [
    TimeFormatPipe,
    TimeCheckerPipe,
    NiceDatePipe,
    TimesPipe,
    HandleNamePipe,
    TimeZonePipe,
    UrlifyPipe,
    TruncatePipe
  ],
  providers: [],
})
export class PipesModule { }
