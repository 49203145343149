<div class="help-widget aligned__right chat">
  <div class="widget__header">
    <div class="title">
    </div>
    <div class="controls">
      <button class="close" (click)="closeUI()">&times;</button>
    </div>
  </div>
  <div class="widget__content">
    <div class="welcome">
      <div class="title">
        <span>Welcome to</span>
        <h5>
          Suburban Wellness SC Support Center
        </h5>
      </div>
      <div class="icon">
        <img src="assets/images/widget-icon-livechat.png" alt="livechat icon">
      </div>
      <div class="text">
        <p>Choose your desired method to begin conversation</p>
      </div>
      <div class="btns">
        <button class="white" type="submit">Request A Callback</button>
        <button type="submit">Start Chat Now</button>
      </div>
    </div>
  </div>
</div>