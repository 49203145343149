import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, Event } from '@angular/router';

@Component({
  selector: 'swapp-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  lat = 41.602802;
  lng = -87.9327017;

  isHomePage: boolean;
  excludedPage: boolean;

  timings2 = [{
    day: 'Monday',
    timing: '1:00 pm - 6:00 pm'
  },
  {
    day: 'Thursday',
    timing: '9:00 am - 1:00 pm'
  }];

  timings1 = [{
    day: 'Monday',
    timing: '9:00 am - 11:30 am'
  }, {
    day: 'Tuesday',
    timing: '10:00 am - 6:00 pm'
  }, {
    day: 'Friday',
    timing: '9:00 am - 12:00 pm'
  }];

  constructor(
    private router: Router,
  ) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart || event instanceof NavigationEnd) {
        const url = event.url.includes('#') ? event.url.split('#')[0] : event.url
        this.isHomePage = url === '/' ? true : false;
        const pages = ['/contact'];
        this.excludedPage = pages.includes(url);
      }
    });
  }

  ngOnInit(): void {
  }

  scrollToElement(id: string) {
    if (!this.isHomePage && id !== 'header' && id !== 'hero') {
      this.router.navigate(['/'], { fragment: id })
        .then(() => {
          setTimeout(() => {
            const el: HTMLElement = document.querySelector('#' + id);
            el.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }, 200);
        });
    } else {
      setTimeout(() => {
        const el: HTMLElement = document.querySelector('#' + id);
        el.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 200);
    }
  }

  gotoHomeAppointmentSection() {
    const id = 'appointments';
    this.router.navigate(["/"], { fragment: id }).then(() => {
      const el: HTMLElement = document.querySelector("#" + id);
      setTimeout(() => {
        el.scrollIntoView({ behavior: "smooth", block: "start" });
      }, 200);
    });
  }

  bookingViaFooterBtn() {

    if (this.isHomePage) {
      this.scrollToElement('appointments');
    } else {
      this.gotoHomeAppointmentSection();
    }
  }


}
